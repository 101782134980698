import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FluidImage } from "@components/Image"
import Link from "@components/Link"
import Accordion from "@components/Accordion"
import ArrowSvg from "@images/Comic__icn__arrow.svg"

import css from "@css/components/blocks/comic/Character.module.styl"

export default function Charactor()
{
    const q = useStaticQuery(graphql`
        query {
            intro : file(relativePath: { eq: "Comic__img__charactor-intro@2x.jpg" }) {
                childImageSharp { fluid(maxWidth: 600) { ...GatsbyImageSharpFluid } }
            }
            comachi : file(relativePath: { eq: "Comic__img__chara-comachi@2x.jpg" }) {
                childImageSharp { fluid(maxWidth: 140) { ...GatsbyImageSharpFluid } }
            }
            hakase : file(relativePath: { eq: "Comic__img__chara-hakase@2x.jpg" }) {
                childImageSharp { fluid(maxWidth: 140) { ...GatsbyImageSharpFluid } }
            }
            q65 : file(relativePath: { eq: "Comic__img__chara-q65@2x.jpg" }) {
                childImageSharp { fluid(maxWidth: 140) { ...GatsbyImageSharpFluid } }
            }
            qcro : file(relativePath: { eq: "Comic__img__chara-qcro@2x.jpg" }) {
                childImageSharp { fluid(maxWidth: 140) { ...GatsbyImageSharpFluid } }
            }
            serifukantoku : file(relativePath: { eq: "Comic__img__chara-serifukantoku@2x.jpg" }) {
                childImageSharp { fluid(maxWidth: 140) { ...GatsbyImageSharpFluid } }
            }
            door : file(relativePath: { eq: "Comic__img__door@2x.jpg" }) {
                childImageSharp { fluid(maxWidth: 1440) { ...GatsbyImageSharpFluid } }
            }
        }
    `)

    const data = [
        {
            img: q.comachi,
            title: '笑顔で未来をつくる22世紀型アイドル',
            name: 'ミライ小町',
            features: [
                `研究所の研究員として過ごしている22世紀型アイドル<br>
                <span>※21世紀を学びに来た未来の女の子らしいが、詳細はヒミツ…</span>`,
                `好奇心旺盛で天然なところがあり、庶民的で話しかけやすい雰囲気がある`,
                `ねこやにんじんが好き`,
                `誕生日が2月22日など、「2」に関係することが多い`,
                `理系知識はある程度あるけれど、実はそこまで詳しくない`,
                `やるときはすごくしっかりやる<br>
                <span>※なんでも、仕事をバッチリキメるモードがあるとか…</span>`,
                `どこに住んでいるかわからないなど、いろいろと謎が多い<br>
                <span>※ついつい博士に色々話しちゃうけれど、話しすぎたらモノワスレハンマーで頭を殴って記憶を飛ばすという荒業も…？</span>`,
            ],
        },
        {
            img: q.hakase,
            title: 'アソビも研究も熱心な みんなの世話役',
            name: '博士(はかせ)',
            features: [
                `ロジカルで、散らばった話をまとめるのが上手`,
                `会社の設備を我が物のように使うちゃっかりした性格`,
                `何でもAIにしてしまおうとするところがある`,
                `飼っている猫が、ミライ小町に抱っこされているのをいつも羨ましそうに見ている`,
                `何かを分析するとき、定規を片手に持ち、腕を伸ばして「ふむふむ」とじろじろ見るクセがある<br>
                <span>※たまにミライ小町のことを定規ごしに見ようとする</span>`,
                `キューゴローをありもので急いで作ったため、家電などの寄せ集めなのだが、その見た目を気に入っている`,
                `ミライ小町が未来から来たことに何となく気がついていて、何かと未来のことを聞きだそうとする`,
            ],
        },
        {
            img: q.q65,
            title: 'アナログゲーム大好き 愛されロボット',
            name: 'Q-56(キューゴロー)',
            features: [
                `性格はおじさん的で、女の子によく嫌がられる<br>
                <span>※キューシローのように女の子からだっこされたいと思っているが、（材質的に）重たいのでだっこされたことがない</span>`,
                `大好きなものに熱中するあまり、我を忘れがち`,
                `いまはゲームが大好きで、ゲームしていれば幸せ`,
                `「ゲームオーバー」という言葉に弱い`,
                `博士が急いで作ったので、家電などの寄せ集めのパーツでできている`,
                `博士はそれを気に入っているが、キューゴローはいつかちゃんとつくり直してほしいと思っている`,
                `周囲の目を気にしすぎるところがあり、失敗するとキョロキョロするクセがある`,
            ],
        },
        {
            img: q.qcro,
            title: 'だいたい天使、たまに豹変 AIロボット',
            name: 'QC-RO(キューシロー)',
            features: [
                `ロボットの中でも特に女の子からすごくかわいがられる存在で、よくだっこされる`,
                `言葉数は少なく、流暢ではない`,
                `胸にクリスタルがあり、付け替えられると性格が変わる`,
                `好奇心旺盛で、何でもかんでもいっぱい吸収しようとする<br>
                <span>※普段はボケっとしているが、日頃から学習している結果、突然すごい解決策を思いついて発言したりする</span>`,
                `日頃から学習しているため言葉を話しているが、突然思い出したように一見脈略のないことも言う不思議なところも…`,
            ],
        },
        {
            img: q.serifukantoku,
            title: 'キャラクターの個性を守る キャラぶれ検出AI',
            name: 'セリフ監督（せりふかんとく）',
            features: [
                `バンダイナムコ研究所が開発運用しているAIセリフ監修支援サービス搭載のAI`,
                `みんなの個性を覚えるのが大好きで、近くで話を聞いていることが多い`,
                `みんなの好きなものなどもよく知っている`,
                `普段は物静かだが、キャラぶれしているセリフを聞くと「っぽくない！っぽくない！」と頭のライトをチカチカさせながら「キャラぶれ」を数値で教えてくれる`,
                `セリフが修正されるといつものニコニコ顔にもどる`,
                `セリフ監修支援の仕事をしており、博士たちと一緒にいないときは仕事に出かけている<br>
                <span>※あなたの知っているあのキャラクターの個性も守ってるかも・・・？</span>`,
            ],
        }
    ]

    return(
        <div className={css.container}>
            <div className={css.content}
            >
                <div className={css.list}>
                    {data.map(({ img, title, name, features }, i) => {
                        return (
                            <div className={css.item} key={i}>
                                <div className={css.head}>
                                    <figure className={css.thumbnail}>
                                        <FluidImage data={img} />
                                    </figure>
                                    <div className={css.detail}>
                                        <p className={css.title}>{title}</p>
                                        <p className={css.name}>{name}</p>
                                    </div>
                                </div>
                                <ul className={css.features}>
                                    {features.map((val, i) => {
                                        return (
                                            <li className={css.item} key={i} dangerouslySetInnerHTML={{ __html: val }} />
                                        )
                                    })}
                                </ul>
                            </div>
                            )
                        })}
                    </div>
                </div>
            </div>
    )
}
