import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FluidImage } from "@components/Image"
import Link from "@components/Link"
import Accordion from "@components/Accordion"
import ArrowSvg from "@images/Comic__icn__arrow.svg"

import css from "@css/components/blocks/comic/Detail.module.styl"

export default function Detail()
{
    const q = useStaticQuery(graphql`
        query {
            intro : file(relativePath: { eq: "Comic__img__charactor-intro@2x.jpg" }) {
                childImageSharp { fluid(maxWidth: 600) { ...GatsbyImageSharpFluid } }
            }
            comachi : file(relativePath: { eq: "Comic__img__chara-comachi@2x.jpg" }) {
                childImageSharp { fluid(maxWidth: 140) { ...GatsbyImageSharpFluid } }
            }
            hakase : file(relativePath: { eq: "Comic__img__chara-hakase@2x.jpg" }) {
                childImageSharp { fluid(maxWidth: 140) { ...GatsbyImageSharpFluid } }
            }
            q65 : file(relativePath: { eq: "Comic__img__chara-q65@2x.jpg" }) {
                childImageSharp { fluid(maxWidth: 140) { ...GatsbyImageSharpFluid } }
            }
            qcro : file(relativePath: { eq: "Comic__img__chara-qcro@2x.jpg" }) {
                childImageSharp { fluid(maxWidth: 140) { ...GatsbyImageSharpFluid } }
            }
            serifukantoku : file(relativePath: { eq: "Comic__img__chara-serifukantoku@2x.jpg" }) {
                childImageSharp { fluid(maxWidth: 140) { ...GatsbyImageSharpFluid } }
            }
            door : file(relativePath: { eq: "Comic__img__door@2x.jpg" }) {
                childImageSharp { fluid(maxWidth: 1440) { ...GatsbyImageSharpFluid } }
            }
        }
    `)

    const containerRef = React.useRef()
    const bannerRef = React.useRef()
    const contentRef = React.useRef()
    return(
        <div className={css.container}>
            <div className={css.content}>
                <div className={css.hero}>
                    <div className={css.paragraph}>
                        {/* <h2>
                            がんばれ！<br/>ミライ小町ちゃん！
                        </h2> */}
                        <h3>『漫画・構成：Bou』</h3>
                        <p>
                            ミライ小町はバンダイナムコ研究所で働く研究員であり、その研究所の所長である博士。そして、その博士によって生み出されたロボットたち、Q-56(キューゴロー)、QC-RO(キューシロー)、セリフ監督君が登場人物です。
                        </p>
                        <p>
                            Q-56(キューゴロー)とQC-RO(キューシロー)が、博士の助手として、日々、博士の研究をサポートしていたある日、ある研究の失敗により爆発が起こります。その爆発の煙の中から出てきた女の子が、ミライ小町でした。<br/>
                            ミライ小町は、爆発とともに現れたにも関わらず驚く様子もなく、「ここは、何年のどこで、あなた（博士）は誰なのか」を尋ねます。
                        </p>
                        <p>
                            物語は、そんな不思議な問いかけから、はじまります ー
                        </p>
                    </div>
                    <figure className={css.thumbnail}>
                        <FluidImage data={q.door} />
                    </figure>
                </div>
            </div>
        </div>
    )
}
